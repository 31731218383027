import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

/**
 *
 * @param {object} props
 * @param {object} props.icon
 * @returns {JSX.Element}
 */
const Icon = ({ link, icon, title, toUrl, loading }) => {
  const [linkType, setLinkType] = useState("slug");
  const isNotMobile = useMediaQuery({
    query: "(min-width: 800px)",
  });

  useEffect(() => {
    const linkState = toUrl?.split("/");

    if (
      linkState !== undefined &&
      linkState?.includes("servicioshiltonmonterrey.com") &&
      !linkState?.includes("pdf")
    ) {
      if (linkState?.includes("pdf")) {
        setLinkType("pdf");
      }
    } else if (linkState !== undefined) {
      setLinkType("_blank");
      return;
    }
  }, [toUrl]);

  return (
    <Link
      {...(linkType === "_blank" && { target: "_blank" })}
      to={{
        pathname: linkType === "_blank" ? toUrl : link,
        state: { pdf: toUrl },
      }}
      style={{
        textDecoration: "none",
        background: "#000000",
        opacity: 0.67,
        transition: "all 0.2 ease",
        "&:hover": { opacity: 0.1 },
        backdropFilter: "blur(15px)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: isNotMobile ? "space-between" : "center",
        padding: isNotMobile ? "70px 20px" : "10px",
      }}
    >
      <section>
        {loading ? (
          <section
            style={{
              marginTop: "10px",
              width: isNotMobile ? "100%" : "90%",
              height: "auto",
            }}
          ></section>
        ) : (
          <img
            src={icon}
            alt={title}
            style={{
              marginTop: "10px",
              marginBottom: isNotMobile ? "30px" : "20px",
              width: isNotMobile ? "100%" : "90%",
              height: "auto",
            }}
          />
        )}
      </section>
      {loading ? (
        <section
          style={{
            textAlign: "center",
            color: "white",
            padding: "0 20px",
            boxSizen: "border-box",
            fontSize: isNotMobile ? "16px" : "13px",
          }}
        ></section>
      ) : (
        <p
          style={{
            textAlign: "center",
            color: "white",
            padding: "0 20px",
            boxSizen: "border-box",
            fontSize: isNotMobile ? "16px" : "13px",
          }}
        >
          {title}
        </p>
      )}
    </Link>
  );
};

export default Icon;
