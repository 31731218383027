import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";
import Main from "./pages/Main";
import PageContextProvider from "./Context/PageContext";
import "./styles.css";

function App() {
  return (
    <React.StrictMode>
      <PageContextProvider>
        <BrowserRouter>
          <Route
            render={({ location }) => {
              return (
                <PageTransition
                  preset="moveToLeftFromRight"
                  transitionKey={location.pathname}
                  className="animation-wrapper"
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/es" />}
                    />
                    <Route exact path="/en" component={Main} />
                    <Route exact path="/en/:name" component={Main} />
                    <Route exact path="/en/:name/:link" component={Main} />
                    <Route exact path="/es" component={Main} />
                    <Route exact path="/es/:name" component={Main} />
                    <Route exact path="/es/:name/:link" component={Main} />
                  </Switch>
                </PageTransition>
              );
            }}
          />
        </BrowserRouter>
      </PageContextProvider>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
