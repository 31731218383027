import { useContext } from "react";
import { PageContext } from "../../Context/PageContext";
import { useState, useEffect } from "react";
import api from "../../api";
import { useLocation, useHistory } from "react-router-dom";
import Footer from "../../components/Footer";
import { useMediaQuery } from "react-responsive";
import HotelLogo from "../../assets/hotel-hilton.svg";
import Icon from "../../components/Icon";
import ChevronIcon from "../../assets/chevron-left.svg";
import "./style.css";

/**
 * Main Container
 * @returns {JSX.Element}
 */
const MainContent = () => {
  const { language } = useContext(PageContext);
  const [content, setContent] = useState({ service: {}, data: [] });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const listOfPaths = location.pathname.split("/");
  const baseRoute = listOfPaths[1];
  const slug = listOfPaths[listOfPaths.length - 1];
  const pdfUrl = content?.service?.to_url;
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    let url = "";
    if (slug !== "en" && slug !== "es") {
      url = `/pages?type=menu&lang=${language}&slug=${slug}`;
    } else {
      url = `/pages?type=service&lang=${language}`;
    }

    if (pdfUrl === "#" || !pdfUrl) {
      api
        .get(url)
        .then(({ data }) => setContent(data))
        .catch((error) => console.log("error: ", error));
    }
    setLoading(false);
  }, [slug, baseRoute, pdfUrl, language]);

  const isNotMobile = useMediaQuery({
    query: "(min-width: 800px)",
  });

  const islaptop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const goBackHandler = () => {
    history.goBack();
  };

  const mainStyles = {
    backgroundPosition: "center bottom",
    backgroundSize: "cover",
    minHeight: isNotMobile ? "95vh" : "88vh",
    dislay: "flex",
    flexDiration: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <section>
      {listOfPaths?.length === 3 && (
        <button onClick={goBackHandler} className="link-back">
          <img src={ChevronIcon} alt="back" className="back-img-link" />
        </button>
      )}
      <Footer />
      <main
        style={{
          ...mainStyles,
          backgroundColor: "#172445",
          backgroundImage: `url(${
            isNotMobile
              ? content?.page?.full_background_url
              : content?.page?.mobile_background_url
          })`,
        }}
      >
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: islaptop ? "20px" : "10%",
          }}
        >
          <img
            src={content?.page?.full_image_url || HotelLogo}
            alt="hotel hilton"
          />
        </section>

        <section
          className="menus-display-grid"
          style={{
            flexWrap: "wrap",
            gap: isNotMobile ? "60px" : "20px",
            padding: isNotMobile ? "40px" : "20px 20px",
            margin: "auto",
          }}
        >
          {content?.data?.map((item, index) => (
            <Icon
              key={index}
              title={item?.name}
              icon={item?.full_icon_url}
              link={location?.pathname + "/" + item?.slug}
              toUrl={item?.to_url}
              loading={loading}
            />
          ))}
        </section>
      </main>
    </section>
  );
};

export default MainContent;
