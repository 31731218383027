import { useContext, useEffect } from "react";
import { PageContext } from "../Context/PageContext";
import { useLocation } from "react-router-dom";
import { MainContent, PdfViewerContainer } from "../containers";

const Main = () => {
  const { setLanguage } = useContext(PageContext);
  const { state, pathname } = useLocation();
  const listOfPaths = pathname.split("/");
  const baseRoute = listOfPaths[1];

  useEffect(() => {
    setLanguage(baseRoute);
  }, [baseRoute]);

  let element;
  if (state?.pdf && state?.pdf?.includes("pdf")) {
    element = <PdfViewerContainer pdfUrl={state.pdf} />;
  } else {
    element = <MainContent />;
  }
  return <div style={{backgroundColor: "black"}}>{element}</div>;
};

export default Main;
