import { useHistory } from "react-router-dom";
import PDFViewer from "tailwind-pdf-viewer/dist";
import "tailwind-pdf-viewer/dist/style.css";
import HiltonLogo from "../../assets/hotel-hilton.svg";
import ChevronIcon from "../../assets/chevron-left.svg";

import "./styles.css";

const PdfViewerContainer = ({ pdfUrl }) => {
  const history = useHistory();

  const goBackHandler = () => {
    history.goBack(-1);
  };

  return (
    <div>
      <section className="navbar-dark">
        <button onClick={goBackHandler}>
          <img src={ChevronIcon} alt="back" className="btn-back-img" />
        </button>
        <img src={HiltonLogo} alt="Hilton" />
      </section>
      <section>
        <PDFViewer pdfURL={pdfUrl}></PDFViewer>
      </section>
      <section className="pdf-controls">
        <button>Previous</button>
        <button>Next</button>
      </section>
    </div>
  );
};

export default PdfViewerContainer;
