import { createContext, useState, useEffect } from "react";
import api from "../api";

export const PageContext = createContext({});

const PageContextProvider = ({ children }) => {
  const [language, setLanguage] = useState("es");
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    api
      .get(`/systemConfigurations?lang=${language}`)
      .then((response) => setSettings(response?.data?.data))
      .catch((error) => console.log("error: ", error));
  }, [language]);

  return (
    <PageContext.Provider
      value={{
        language,
        setLanguage,
        settings,
        setSettings,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContextProvider;
