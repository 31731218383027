import { useContext } from "react";
import { Link } from "react-router-dom";
import EngIcon from "../assets/iconos/eng.svg";
import EspIcon from "../assets/iconos/esp.svg";
import { PageContext } from "../Context/PageContext";

const linkStyles = {
  color: "white",
  textDecoration: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

/**
 * Footer component
 * @returns {JSX.Element}
 */
const Footer = () => {
  const { language, setLanguage, settings } = useContext(PageContext);

  const chooseLanguage = () => {
    setLanguage((prev) => (prev === "es" ? "en" : "es"));
  };
  const phone = settings?.find((item) => item?.name?.includes("Teléfono"));
  const instagram = settings?.find((item) => item?.name?.includes("Instagram"));

  return (
    <footer
      style={{
        backgroundColor: "black",
        height: "5vh",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "20px 20px",
        fontSize: "13px",
      }}
    >
      <a style={linkStyles} href={instagram?.mixed_value}>
        @{instagram?.mixed_value?.split("/")[3]}
      </a>
      <a style={linkStyles} href={`tel:${phone?.mixed_value} `}>
        Tel: {phone?.mixed_value}
      </a>
      <button style={linkStyles} onClick={chooseLanguage}>
        <Link
          to={`/${language === "es" ? "en" : "es"}`}
          replace
          style={linkStyles}
        >
          {language === "en" ? (
            <img
              src={EspIcon}
              alt="Spanish language"
              style={{ marginRight: "4px" }}
            />
          ) : (
            <img
              src={EngIcon}
              alt="English language"
              style={{ marginRight: "4px" }}
            />
          )}

          {language === "es" ? "En" : "Es"}
        </Link>
      </button>
    </footer>
  );
};

export default Footer;
